import Vue from 'vue'
import Router from 'vue-router'
import homepage from '@/components/homepage/homepage.vue'

import companies from '@/components/companies/companies.vue'

import applications from '@/components/applications/applications.vue'
import contracts from '@/components/contracts/contracts.vue'
import certificates from '@/components/certificates/certificates.vue'
import audits from '@/components/audits/audits.vue'
import checklists from '@/components/checklists/checklists.vue'
import curricula from '@/components/curricula/curricula.vue'

import externalaudit from '@/components/externalaudit/externalaudit.vue';
import externalcontract from '@/components/externalcontract/externalcontract.vue';
import externalsignature from '@/components/externalsignature/externalsignature.vue'

import complaints from '@/components/complaints/complaints.vue'
import qualifications from '@/components/qualifications/qualifications.vue'
import competences from '@/components/competences/competences.vue'
import monitorings from '@/components/monitorings/monitorings.vue'
import jobs from '@/components/jobs/jobs.vue'
import jobsappointments from '@/components/jobsappointments/jobsappointments.vue'
import laws from '@/components/laws/laws.vue'
import trainings from '@/components/trainings/trainings.vue'
import impartialitycommittees from '@/components/impartialitycommittees/impartialitycommittees.vue'
import documentsconsultation from '@/components/documentsconsultation/documentsconsultation.vue'
import documentssystem from '@/components/documentssystem/documentssystem.vue'
import interestedparties from '@/components/interestedparties/interestedparties.vue'
import risks from '@/components/risks/risks.vue'
import nonconformities from '@/components/nonconformities/nonconformities.vue'
import internalaudits from '@/components/internalaudits/internalaudits.vue'
import managementreviews from '@/components/managementreviews/managementreviews.vue'
import mails from '@/components/mails/mails.vue'
import login from '@/components/login/login.vue'
import users from '@/components/users/users.vue'


import axios from 'axios';
import store from '../store';
import mixin from '@/mixins/index'

Vue.use(Router)

Vue.mixin(mixin);

const router = new Router  ({
  routes:[{
    path: '/homepage',
    name: 'homepage',
    component: homepage,
    meta: {
      title: '',
      requiresAuth: true,
    },
  },{
    path: '/companies',
    name: 'companies',
    component: companies,
    meta: {
      title: 'Companies',
      requiresAuth: true,
    },
  },{
    path: '/applications',
    name: 'applications',
    component: applications,
    meta: {
      title: 'Applications',
      requiresAuth: true,
    },
  },{
    path: '/contracts',
    name: 'contracts',
    component: contracts,
    meta: {
      title: 'Contracts',
      requiresAuth: true,
    },
  },{
    path: '/certificates',
    name: 'certificates',
    component: certificates,
    meta: {
      title: "Certificates",
      requiresAuth: true,
    },
  },{
    path: '/audits',
    name: 'audits',
    component: audits,
    meta: {
      title: 'Audits',
      requiresAuth: true,
    },
  },{
    path: '/checklists',
    name: 'checklists',
    component: checklists,
    meta: {
      title: 'Checklists',
      requiresAuth: true,
    },
  },{
    path: '/documentsconsultation',
    name: 'documentsconsultation',
    component: documentsconsultation,
    meta: {
      title: 'Documents consultation',
      requiresAuth: true,
    },
  },{
    path: '/curricula',
    name: 'curricula',
    component: curricula,
    meta: {
      title: 'Curricula',
      requiresAuth: true,
    },
  },{
    path: '/qualifications',
    name: 'qualifications',
    component: qualifications,
    meta: {
      title: 'Qualifications',
      requiresAuth: true,
    },
  },{
    path: '/competences',
    name: 'competences',
    component: competences,
    meta: {
      title: 'Competences',
      requiresAuth: true,
    },
  },{
    path: '/monitorings',
    name: 'monitorings',
    component: monitorings,
    meta: {
      title: 'Monitorings',
      requiresAuth: true,
    },
  },{
    path: '/interestedparties',
    name: 'interestedparties',
    component: interestedparties,
    meta: {
      title: 'Interested parties',
      requiresAuth: true,
    },
  },{
    path: '/documentssystem',
    name: 'documentssystem',
    component: documentssystem,
    meta: {
      title: 'Documents system',
      requiresAuth: true,
    },
  },{
    path: '/risks',
    name: 'risks',
    component: risks,
    meta: {
      title: 'Risks assessment',
      requiresAuth: true,
    },
  },{
    path: '/laws',
    name: 'laws',
    component: laws,
    meta: {
      title: 'Laws',
      requiresAuth: true,
    },
  },{
    path: '/externalaudit/:token',
    name: 'externalaudit',
    component: externalaudit,
    props: true,
    meta: {
      title: "Audit - Client",
      requiresAuth: false,
    },
  },{
    path: '/externalcontract/:token',
    name: 'externalcontract',
    component: externalcontract,
    props: true,
    meta: {
      title: "Contract - Client",
      requiresAuth: false,
    },
  },{
    path: '/externalsignature/:token',
    name: 'externalsignature',
    component: externalsignature,
    props: true,
    meta: {
      title: "Signature",
      requiresAuth: false,
    },
  },{
    path: '/jobs',
    name: 'jobs',
    component: jobs,
    meta: {
      title: 'Jobs description',
      requiresAuth: true,
    }
  },{
    path: '/complaints',
    name: 'complaints',
    component: complaints,
    meta: {
      title: 'Complaints',
      requiresAuth: true,
    },
  },{
    path: '/jobsappointments',
    name: 'jobsappointments',
    component: jobsappointments,
    meta: {
      title: 'Jobs appointmente',
      requiresAuth: true
    }
  },{
    path: '/trainings',
    name: 'trainings',
    component: trainings,
    meta: {
      title: 'Trainings',
      requiresAuth: true,
    },
  },{
    path: '/impartialitycommittees',
    name: 'impartialitycommittees',
    component: impartialitycommittees,
    meta: {
      title: 'Impartiality committees',
      requiresAuth: true,
    },
  },{
    path: '/nonconformities',
    name: 'nonconformities',
    component: nonconformities,
    meta: {
      title: 'Non conformities',
      requiresAuth: true,
    },
  },{
    path: '/internalaudits',
    name: 'internalaudits',
    component: internalaudits,
    meta: {
      title: 'Internal audits',
      requiresAuth: true,
    },
  },{
    path: '/managementreviews',
    name: 'managementreviews',
    component: managementreviews,
    meta: {
      title: 'Management reviews',
      requiresAuth: true,
    },
  },{
    path: '/mails',
    name: 'mails',
    component: mails,
    meta: {
      title: 'Email',
      requiresAuth: true,
    },
  },{
    path: '/users',
    name: 'users',
    component: users,
    meta: {
      title: 'Users',
      requiresAuth: true,
    },
  },{
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },{
    path: "*",
    redirect: '/homepage',
  }]
})

router.beforeEach((to, from, next) => {
  document.title = "Gestionale" + ( to.meta.title !== '' ? ' - ' + to.meta.title : '');
  if (to.meta.requiresAuth === true) {
    if (store.getters.getToken === null){
      store.commit("setNavbar", false);
      router.push('login');
    } else {

      let promise = axios.post("api/auths/routes", { token: store.getters.getToken });
      return promise.then((data) => {            
        if (data.data.result === true) {
          store.commit("setRoutes", data.data.data.routes)
          store.commit("setNavbar", true);
          next();
        } else {
          store.commit("setNavbar", false);
          router.push('login');
        }
        return(true)
      }).catch(() => {
        store.commit("setNavbar", false);
        router.push('login');
        return []
      })  
    }
  } else {
    next();
  }
})

export default router;