<template>
    <div>
        <b-modal v-if="data !== null" v-model="show" title="Document" @hidden="closeForm()" @close="closeForm()">
            <b-form-group label="File">
                <b-form-file @change="fileChange"/>
            </b-form-group>
            <template v-slot:modal-footer>
                <div class="w-100">
                    <b-btn variant="success" v-show="data.document.locked === false" class="mr-2" @click="saveData">Save</b-btn>
                    <b-btn variant="danger" class="mr-2" v-show="data.document.id > 0 && data.document.locked === false" @click="deleteData">Delete</b-btn>
                    <b-btn variant="primary" v-show="data.document.id > 0 && data.document.locked === false" @click="lockData(data.document.id)">Lock</b-btn>
                    <b-btn variant="warning" v-show="data.document.id > 0 && data.document.locked === true" @click="unlockData(data.document.id)">Unlock</b-btn>
                </div>
            </template>
        </b-modal>
        <form method="post" action="/api/trainings/document/download" ref="downloadFormSubmit" target="_blank">
            <input type="hidden" name="token" :value="token">
            <input type="hidden" ref="downloadFormId" name="id">
        </form>
    </div>
</template>

<script>
export default {
    name: "trainingdocument",
    data() {
        return {
            show: false,
            data: null,
        }
    },
    computed: {
        token (){
            return this.$store.getters.getToken;
        }
    },
    methods: {
        loadData(id ){
            this.openHttp("trainings/document", { id: id }).then((result) => {
                this.data = result;
                this.show = true;
            })
        },
        fileChange(event){
            var reader = new FileReader();
            if (event.target.files != null){
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.target.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.content = reader.result;
                }
            } else {
                reader.readAsDataURL(event.dataTransfer.files[0]);
                reader.onload = ()=> {
                    this.data.document.extension = event.dataTransfer.files[0].name.split(".").slice(-1)[0].toLowerCase();
                    this.data.document.content = reader.result;
                }
            }
        },
        downloadData(id){
            this.$refs.downloadFormId.value = id;
            this.$refs.downloadFormSubmit.submit();
        },
        generateData(id){
            this.saveHttp("trainings/document/generate", {id: id});
        },
        saveData(){
            this.saveHttp("trainings/document/save", this.data.document).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        deleteData(){
            this.deleteHttp("trainings/document/delete", { id: this.data.document.id }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        lockData(documentsId){
            this.saveHttp("trainings/document/lock", { id: documentsId }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        unlockData(documentsId){
            this.saveHttp("trainings/document/unlock", { id: documentsId }).then((result) => {
                if ( result !== null ) this.closeForm(true);
            })
        },
        closeForm(reload = false){
            this.show = false;
            this.data = null;
            if (reload === true ) this.$emit("update");
        }
    },
}
</script>