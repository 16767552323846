<template>
  <div>
    <b-modal v-if="data !== null" v-model="show" size="lg" title="Training" scrollable @hidden="closeForm()" @close="closeForm()">
      <trainingquestions ref="questions" @update="loadData(data.training.id)"/>
      <signature ref="signature" @update="loadData(data.training.id)"/>
      <document ref="document" @update="loadData(data.training.id)"/>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Training" active>
            <b-form-group label="Training type">
              <multiselect v-model="trainingstypesSupport" :options="data.trainingstypes" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.training.trainingstypes = updateCombo(trainingstypesSupport)"/>
            </b-form-group>
            <b-row>
              <b-col lg="6">
                <b-form-group label="Teachers">
                  <b-form-input v-model="data.training.teachers"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="From">
                  <b-form-input type="date" v-model="data.training.fromdate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="To">
                  <b-form-input type="date" v-model="data.training.todate"/>
                </b-form-group>
              </b-col>
              <b-col lg="2">
                <b-form-group label="Hours">
                  <b-form-input type="number" v-model="data.training.hours"/>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group label="Participants" v-if="level >= 2">
              <multiselect v-model="usersSupport" :options="data.users" :multiple="true" placeholder="" selectLabel="" deselectLabel="" track-by="id" label="val" @input="data.training.users = updateCombo(usersSupport)"/>
            </b-form-group>
            <b-form-group label="Note">
              <b-form-textarea v-model="data.training.note" rows="3"/>
            </b-form-group>
            <b-form-group label="Documents / Slides">
              <b-table no-border-collapse responsive show-empty :items="data.documents" :fields="fields.documents">
                <template v-slot:cell(id)="row">
                  <b-btn variant="primary" v-show="row.value > 0" size="sm" @click="$refs.document.downloadData(row.value)">Download</b-btn>
                </template>
              </b-table>
            </b-form-group>
          </b-tab>
          <b-tab title="Participants" v-if="data.training.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive show-empty :items="data.report" :fields="fields.report">
              <template v-slot:empty>There are no results</template>
              <template v-slot:cell(signature)="row">
                <b-btn v-if="row.item.signature_id > 0" :variant="row.item.signature_check === true ? 'success' : 'danger'" size="sm" @click="$refs.signature.loadData(row.item.signature_id)">{{ row.item.signature_check === true ? 'Signed' : 'To Be signed' }}</b-btn>
              </template>
              <template v-slot:cell(questions)="row">
                <b-btn variant="info" size="sm" @click="$refs.questions.loadData(row.item.id)">Questionnaire</b-btn>
              </template>
              <template v-slot:cell(documents_id)="row">
                <b-btn variant="primary" size="sm" v-if="row.value > 0" @click="$refs.document.downloadData(row.value)">Download</b-btn>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Results" v-if="data.training.id > 0">
            <b-table no-border-collapse :sticky-header="(winHeight - 375) + 'px'" responsive show-empty :items="data.results" :fields="fields.results">
              <template v-slot:empty>There are no results</template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-btn variant="primary" class="mr-2" @click="loadData(data.training.id)">Refresh</b-btn>
          <b-btn variant="success" class="mr-2" v-show="level >= 2" @click="saveData()">Save</b-btn>
          <b-btn variant="danger" class="mr-2" v-show="level >= 2 && data.training.id > 0" @click="deleteData()">Delete</b-btn>
          <b-btn variant="info" class="mr-2" v-show="level >= 2 && data.training.id > 0" @click="$refs.document.generateData(data.training.id)">Generate</b-btn>
          <b-btn variant="primary" v-show="level >= 2 && data.training.documents_id > 0" @click="$refs.document.downloadData(data.training.documents_id)">Download</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import document from './document.vue';
import signature from './signature.vue';
import trainingquestions from "./trainingquestions.vue";

export default {
  name: "training",
  components: { document, signature, trainingquestions },
  data() {
    return {
      trainingstypesSupport: [],
      usersSupport: [],
      show: false,
      data: null,
      fields: {
        documents: [{
          key: "code",
          label: "Code",
        },{
          key: "documentname",
          label: "Name",
        },{
          key: "id",
          label: "",
        }],
        report: [{
          key: "fullname",
          label: "Fullname",
          sortable: true
        },{
          key: "signature",
          label: "Signature"
        },{
          key: "questions",
          label: "Questions"
        },{
          key: "percentagedone",
          label: "Done",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        },{
          key: "percentagecorrect",
          label: "Correct",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        },{
          key: "documents_id",
          label: "Certificate"
        }],
        results: [{
          key: "fullname",
          label: "Fullname",
          sortable: true
        },{
          key: "datetime",
          label: "Date",
          sortable: true,
        },{
          key: "percentage",
          label: "Correct",
          sortable: true,
          formatter: value => {
            if (value == null) return null;
            return value + "%";
          }
        }]
      }
    }
  },
  computed: {
    winHeight (){
      return this.$store.getters.getWinHeight;
    },
    level(){
      return this.$store.getters.getRoutes.find(x => x.route === "trainings").level;
    }
  },
  methods: {
    loadData(id){
      this.openHttp("trainings/training", { id: id }).then((result) => {
        this.trainingstypesSupport = this.convertCombo(result.trainingstypes, result.training.trainingstypes);
        this.usersSupport = this.convertCombo(result.users, result.training.users);
        this.data = result;
        this.show = true;
      })
    },
    saveData(){
      this.saveHttp("trainings/training/save", this.data.training).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    deleteData(){
      this.deleteHttp("trainings/training/delete", { id: this.data.training.id }).then((result) => {
        if ( result !== null ) this.closeForm(true);
      })
    },
    closeForm(reload = false){
      this.show = false;
      this.data = null;
      if (reload === true) this.$emit('update');
    },
    openExternalSignature (token){
      window.open('/#/externalsignature/' + token);
    }
  }
}
</script>
